import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { ECoverImageLayout } from '../constants';

export const settings = {};

export const styles = {
  coverImageLayout: createStylesParam('coverImageLayout', {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: () => ECoverImageLayout.Large,
  }),

  groupHeaderIconsColor: createStylesParam('groupHeaderIconsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupHeaderBackgroundColor: createStylesParam('groupHeaderBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),

  groupTitleColor: createStylesParam('groupTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupTitleFont: createStylesParam('groupTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  }),

  groupSubtitleColor: createStylesParam('groupSubtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupSubtitleFont: createStylesParam('groupSubtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),

  groupBreadcrumbsColor: createStylesParam('groupBreadcrumbsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupBreadcrumbsFont: createStylesParam('groupBreadcrumbsFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  tabsColor: createStylesParam('tabsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  tabsFont: createStylesParam('tabsFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  selectedTabColor: createStylesParam('selectedTabColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),

  dividerTabWidth: createStylesParam('dividerTabWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),

  dividerTabColor: createStylesParam('dividerTabColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  }),

  coverImageHeight: createStylesParam('coverImageHeight', {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 100 : 240),
  }),
};
