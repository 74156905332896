import { useMemo } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

export function useHighLightColor() {
  const styles = useStyles();

  return useMemo(
    () =>
      styles.get(
        createStylesParam('feed-item-highlight-color', {
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam(`color-8`, 0.3),
        }),
      ),
    [],
  );
}

export function useItemHighlight(highlightedText: string | undefined | null) {
  const preview = useMemo(() => {
    const [html] =
      highlightedText?.match(
        /(?:[\p{L}\p{N}]+\P{L}+){0,20}(\p{L}+)?<em>.*?<\/em>(?:.*?\P{L}+\p{L}+){0,20}/gu,
      ) ?? [];

    // - (?:[\p{L}\p{N}]+\P{L}+){0,20} - Matches up to 20 words or numbers followed by non-letter characters (spaces, punctuation).
    // - (\p{L}+)? - Optionally matches a single word.
    // - <em>.*?<\/em> - Matches text inside <em> tags.
    // - (?:.*?\P{L}+\p{L}+){0,20} - Matches up to 20 words or numbers after the <em> tag.

    const truncatedAfter = !highlightedText?.endsWith(html!);
    const truncatedBefore = !highlightedText?.startsWith(html!);

    return [truncatedBefore && '...', html, truncatedAfter && '...']
      .filter(Boolean)
      .join('');
  }, [highlightedText]);

  const total = useMemo(() => {
    return highlightedText?.match(/<em>/g)?.length ?? 0;
  }, [highlightedText]);

  const count = useMemo(() => {
    return preview?.match(/<em>/g)?.length ?? 0;
  }, [preview]);

  return { preview, total, count };
}
