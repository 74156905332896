import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { groupsSearchSearchResultClickSrc111Evid525 } from '@wix/bi-logger-groups-data/v2';

import type { IFeedItem } from 'api/feed/types';
import { selectGroupSlugById } from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Card, type ICardProps } from 'wui/Card';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Link } from '../../Link';
import { FeedItemHeader } from '../../FeedItem/FeedItemHeader';

import { useHighLightColor, useItemHighlight } from './helpers';
import classes from './styles.scss';

interface IProps {
  item: IFeedItem;
  promote?: boolean;
  origin: string;
}

export function FeedItemSearchResult(props: IProps & Partial<ICardProps>) {
  const { item, className, promote, origin, ...rest } = props;

  const { t } = useTranslation();
  const color = useHighLightColor();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const highlight = useItemHighlight(item.highlight);

  const titleHighlight = useItemHighlight(
    item.highlights?.find((value) => value?.field === 'entity.title')
      ?.highlightedText,
  );
  const contentHighlight = useItemHighlight(
    item.highlights?.find((value) => value?.field === 'rawText')
      ?.highlightedText,
  );

  const isPostTitleEnabled = experiments.enabled(
    'specs.groups.EnablePostTitle',
  );

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const hasMoreResults = isPostTitleEnabled
    ? titleHighlight.total > titleHighlight.count ||
      contentHighlight.total > contentHighlight.count
    : highlight.total > highlight.count;

  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <Card
      {...rest}
      className={cls(classes.root, className)}
      style={{ '--highlight-color': color.value } as React.CSSProperties}
    >
      <FeedItemHeader
        item={item}
        padding="0 SP6"
        promote={promote}
        action={
          <Show if={!isMobile && hasMoreResults}>
            <Typography secondary variant="p2-14">
              {t('groups-web.search.feed-item.more-results')}
            </Typography>
          </Show>
        }
      />

      <CardContent as="div" padding="0 SP6">
        <Show if={!!titleHighlight.total}>
          <Typography
            variant="p2-20"
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: titleHighlight.preview }}
          />
        </Show>

        <Typography
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: isPostTitleEnabled
              ? contentHighlight.preview
              : highlight.preview,
          }}
        />
      </CardContent>

      <CardActions padding="0 SP6">
        <Box
          gap="SP3"
          width="100%"
          align="space-between"
          direction={isMobile && hasMoreResults ? 'vertical' : 'horizontal'}
        >
          <Box gap="SP3">
            <Typography>
              {t('groups-web.discussion.feed.reactions.total_icu', {
                count: item.reactions?.total ?? 0,
              })}
            </Typography>
            <Typography>
              {t('groups-web.discussion.feed.total-comments_icu', {
                count: item.latestComments?.total ?? 0,
              })}
            </Typography>
          </Box>

          <Box align="space-between" verticalAlign="middle">
            <Show if={isMobile && hasMoreResults}>
              <Typography secondary variant="p2-14">
                {t('groups-web.search.feed-item.more-results')}
              </Typography>
            </Show>

            <Link
              variant="link"
              state="group.discussion.post"
              params={{ slug, feedItemId }}
              suffixIcon={<ChevronRightIcon />}
              bi={groupsSearchSearchResultClickSrc111Evid525({
                origin,
                groupId,
                tabName: 'posts',
              })}
            >
              {t('groups-web.search.feed-item.view-post')}
            </Link>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}

FeedItemSearchResult.displayName = 'FeedItemSearchResult';
